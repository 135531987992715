import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { releaseNotes } from './shared/release-notes/releaseNotes.js';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'dsa-dict';
  year = new Date().getFullYear();
  releaseNotes = releaseNotes;
  modalRef: MDBModalRef;
  showAnalyticsModal = true;

  constructor(private meta: Meta, private ms: MDBModalService) {
    this.meta.addTag({
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
    });
    this.meta.addTag({
      name: 'robots',
      content: 'noindex'
    });

    const showmodal = localStorage.getItem('showGAbs');

    if (showmodal !== null) {
      this.showAnalyticsModal = JSON.parse(localStorage.getItem('showGAbs'));
    }
  }

  ngOnInit() {}

  markAsRead(basicModal) {
    basicModal.hide();
    localStorage.setItem('showGAbs', JSON.stringify(false));
  }
}

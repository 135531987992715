import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { ItemModule } from './item/item.module';
import { CustomListsModule } from './custom-lists/custom-lists.module';
import { ReleaseNotesModule } from './release-notes/release-notes.module';
import { CharPageModuleModule } from './char/char-page.module';

@NgModule({
  declarations: [AppComponent, SearchComponent],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ItemModule,
    CustomListsModule,
    ReleaseNotesModule,
    CharPageModuleModule
  ],
  providers: [],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import { CharService } from '../../services/char.service';

@Component({
  selector: 'app-char-creator',
  templateUrl: './char-creator.component.html',
  styleUrls: ['./char-creator.component.scss']
})
export class CharCreatorComponent implements OnInit {
  isCharCreatorOpen = false;
  isCharSelectorOpen = false;
  chars = [];

  newCharName = '';

  attr = {
    MU: '',
    KL: '',
    IN: '',
    CH: '',
    FF: '',
    GE: '',
    KO: '',
    KK: ''
  };

  constructor(private charService: CharService) {}

  ngOnInit() {
    this.charService.onCharSelectorStateChange.subscribe(
      isOpen => (this.isCharSelectorOpen = isOpen)
    );
    this.charService.onListChange.subscribe(lists => {
      this.chars = lists;
    });
  }

  saveNewChar() {
    this.charService.createNewChar(this.newCharName.trim(), this.attr);

    this.newCharName = '';
    this.attr = {
      MU: '',
      KL: '',
      IN: '',
      CH: '',
      FF: '',
      GE: '',
      KO: '',
      KK: ''
    };

    this.isCharCreatorOpen = false;
    this.charService.closeCharCreator();
  }

  close() {
    this.charService.closeCharCreator();
  }
}

import { Component, OnInit } from '@angular/core';
import { ListService } from 'src/app/shared/services/list.service';
import { CharService } from 'src/app/shared/services/char.service';

@Component({
  selector: 'app-custom-list-display',
  templateUrl: './custom-list-display.component.html',
  styleUrls: ['./custom-list-display.component.scss']
})
export class CustomListDisplayComponent implements OnInit {
  constructor(
    private listService: ListService,
    private charService: CharService
  ) {}

  lists = [];

  ngOnInit() {
    this.lists = this.listService.lists;
    this.listService.onListChange.subscribe(lists => {
      this.lists = lists;
    });
  }
  toggleCollapse($event) {
    const collapsedElement = $event.target.parentElement.parentElement.getElementsByTagName(
      'app-item-display'
    )[0];

    const IndicatorClassList = $event.target.children[0].classList;
    const elementClasses = collapsedElement.classList;

    IndicatorClassList.contains('open')
      ? IndicatorClassList.remove('open')
      : IndicatorClassList.add('open');

    elementClasses.contains('collapse')
      ? elementClasses.remove('collapse')
      : elementClasses.add('collapse');
  }
  deleteList(listName) {
    const canDelete = confirm(
      'Bist du sicher, dass du die liste: ' + listName + ' löschen willst ? Sie wird auch aus allen Chars gelöscht.'
    );

    if (canDelete) {
      this.listService.deleteList(listName);
      this.charService.deleteListFromChars(listName);
      return;
    }
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CharService {
  onCharSelectorStateChange = new Subject<any>();
  onListChange = new Subject<any>();

  charSelectorOpenState = false;
  selectedChar: any;
  _lists = [];

  constructor() {
    this._lists = JSON.parse(localStorage.getItem('chars')) || [];
    this.onListChange.next(this._lists);
    this.onListChange.subscribe(lists => {
      if (this._lists && this._lists.length) {
        localStorage.setItem('chars', JSON.stringify(this._lists));
      }
      if (!this._lists.length) {
        localStorage.setItem('chars', JSON.stringify([]));
      }
    });
  }

  deleteListFromChars(listName: any) {
    this._lists.forEach(char => {
      if (char.list.Name === listName) {
        debugger
        char.list = {
          items: []
        };
      }
    });
    this.onListChange.next(this._lists);
  }

  closeCharCreator() {
    this.onCharSelectorStateChange.next((this.charSelectorOpenState = false));
  }

  openListSelector(char) {
    this.selectedChar = char;
    this.onCharSelectorStateChange.next((this.charSelectorOpenState = true));

    this.onListChange.next(this._lists);
  }

  createNewChar(charName: string, attr) {
    const newCar = {
      Name: charName,
      ...attr,
      list: []
    };

    if (!this.isCharNameTaken(charName)) {
      this._lists.unshift(newCar);
      this.onListChange.next(this._lists);
    } else {
      alert('Dieser Charname ist schon bergeben. Bitte wähle einen anderen.');
    }
  }

  isCharNameTaken(charName) {
    return this._lists.find(x => x.Name === charName);
  }

  deleteChar(charName: any) {
    this._lists = this._lists.filter(x => x.Name !== charName);
    this.onListChange.next(this._lists);
  }

  get lists() {
    return this._lists;
  }

  addListToChar(charName, list) {
    this._lists.forEach(char => {
      if (char.Name === charName) {
        char.list = list;
        return;
      }
    });

    this.onListChange.next(this._lists);
  }
}

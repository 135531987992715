/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./release-notes-display.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./release-notes-display.component";
var styles_ReleaseNotesDisplayComponent = [i0.styles];
var RenderType_ReleaseNotesDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReleaseNotesDisplayComponent, data: {} });
export { RenderType_ReleaseNotesDisplayComponent as RenderType_ReleaseNotesDisplayComponent };
function View_ReleaseNotesDisplayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "list-group-item d-flex justify-content-between align-items-center pl-5 py-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_ReleaseNotesDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ul", [["class", "list-group my-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "li", [["class", "list-group-item d-flex justify-content-between align-items-center py-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", "\u00A0-\u00A0", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "badge badge-primary badge-pill"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReleaseNotesDisplayComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_3 = _v.context.$implicit.Notes; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.Name; var currVal_1 = _v.context.$implicit.Version; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.Notes.length; _ck(_v, 4, 0, currVal_2); }); }
export function View_ReleaseNotesDisplayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReleaseNotesDisplayComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.releaseNotesCollection; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ReleaseNotesDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-release-notes-display", [], null, null, null, View_ReleaseNotesDisplayComponent_0, RenderType_ReleaseNotesDisplayComponent)), i1.ɵdid(1, 114688, null, 0, i3.ReleaseNotesDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReleaseNotesDisplayComponentNgFactory = i1.ɵccf("app-release-notes-display", i3.ReleaseNotesDisplayComponent, View_ReleaseNotesDisplayComponent_Host_0, {}, {}, []);
export { ReleaseNotesDisplayComponentNgFactory as ReleaseNotesDisplayComponentNgFactory };

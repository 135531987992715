import { Component, OnInit } from '@angular/core';
import { ListService } from '../../services/list.service';

@Component({
  selector: 'app-add-to-list-selector',
  templateUrl: './add-to-list-selector.component.html',
  styleUrls: ['./add-to-list-selector.component.scss']
})
export class AddToListSelectorComponent implements OnInit {
  constructor(private listService: ListService) {}

  isListSelectorOpen = false;
  isListCreatorOpen = false;
  lists = [];
  newListName = '';

  ngOnInit() {
    this.listService.onListSelectorStateChange.subscribe(
      isOpen => (this.isListSelectorOpen = isOpen)
    );
    this.listService.onListChange.subscribe(lists => {
      this.lists = lists;
    });
  }

  closeCreator() {
    this.isListCreatorOpen = false;
  }

  close() {
    this.listService.closeListSelector();
    this.closeCreator();
  }

  createNewListStart() {
    this.isListCreatorOpen = true;
  }
  saveNewList() {
    if (!this.newListName) {
      return;
    }
    this.listService.createList(this.newListName);
    this.newListName = '';

    this.closeCreator();
  }
  addItemToSelectedList(list) {
    this.listService.addItemToList(list);
    this.close();
  }

}

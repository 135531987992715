import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { FilterType } from '../enums/FilterType';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var SearchService = /** @class */ (function () {
    function SearchService(api) {
        this.api = api;
        this.filteredData = [];
        this.currentSearchTerm = '';
        this.itemSubject = new Subject();
    }
    SearchService.prototype.getfilteredData = function (searchTerm, option) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var normalizedString, _a, _b, _c, _d, _e;
            return tslib_1.__generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        this.currentSearchTerm = searchTerm;
                        normalizedString = this.currentSearchTerm.trim().toLowerCase();
                        if (!normalizedString) {
                            this.currentSearchTerm = '';
                            this.filteredData = [];
                            this.itemSubject.next(this.filteredData);
                            return [2 /*return*/];
                        }
                        this.currentSearchTerm = searchTerm;
                        _a = option;
                        switch (_a) {
                            case FilterType.Name: return [3 /*break*/, 1];
                            case FilterType.Attribute: return [3 /*break*/, 3];
                            case FilterType.StepupFactor: return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 7];
                    case 1:
                        _b = this;
                        return [4 /*yield*/, this.api.getSearchData(normalizedString, FilterType.Name)];
                    case 2:
                        _b.filteredData = (_f.sent())['data'];
                        return [3 /*break*/, 9];
                    case 3:
                        _c = this;
                        return [4 /*yield*/, this.api.getSearchData(normalizedString, FilterType.Attribute)];
                    case 4:
                        _c.filteredData = (_f.sent())['data'];
                        return [3 /*break*/, 9];
                    case 5:
                        _d = this;
                        return [4 /*yield*/, this.api.getSearchData(normalizedString, FilterType.StepupFactor)];
                    case 6:
                        _d.filteredData = (_f.sent())['data'];
                        return [3 /*break*/, 9];
                    case 7:
                        _e = this;
                        return [4 /*yield*/, this.api.getSearchData(normalizedString, FilterType.Name)];
                    case 8:
                        _e.filteredData = (_f.sent())['data'];
                        return [3 /*break*/, 9];
                    case 9:
                        this.itemSubject.next(this.filteredData);
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchService.prototype.onItemGet = function () {
        return this.itemSubject.asObservable();
    };
    SearchService.prototype.filterByAttribute = function (searchString) {
        // return this.filteredData || ([] as any);
    };
    SearchService.prototype.filterByStepupFactor = function (searchString) {
        // return this.filteredData || ([] as any);
    };
    SearchService.ngInjectableDef = i0.defineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.inject(i1.ApiService)); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };

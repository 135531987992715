import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SearchService } from '../services/search.service.js';
import { FilterType } from '../enums/FilterType';
var SearchComponent = /** @class */ (function () {
    function SearchComponent(ss) {
        var _this = this;
        this.ss = ss;
        this.displayData = [];
        this.debounce = function (callback, wait) {
            var timeout;
            return function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var context = _this;
                clearTimeout(timeout);
                timeout = setTimeout(function () { return callback.apply(context, args); }, wait);
            };
        };
        this.debounceHandleSearch = this.debounce(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var searchTerm;
            return tslib_1.__generator(this, function (_a) {
                searchTerm = data.target.value.trim();
                this.ss.getfilteredData(searchTerm, FilterType.Name);
                return [2 /*return*/];
            });
        }); }, 650);
        this.ss.onItemGet().subscribe(function (data) {
            data.length
                ? (_this.dynamicFormClass = 'active-display')
                : (_this.dynamicFormClass = '');
            _this.displayData = data;
        });
    }
    SearchComponent.prototype.handleSearch = function ($event) {
        this.debounceHandleSearch($event);
    };
    SearchComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/];
        }); });
    };
    SearchComponent.prototype.onSubmit = function ($event) {
        $event.preventDefault();
    };
    return SearchComponent;
}());
export { SearchComponent };

<div class="row my-4">
  <div class="col-12">
    <div class="container">
      <div class="title row" *ngIf="chars.length">
        <div class="col-12 mb-3">
          <mdb-card *ngFor="let char of chars" class="mb-3">
            <mdb-card-body>
              <mdb-card-title (click)="toggleCollapse($event)">
                <h4 class="pr-4">
                  {{ char.Name }}
                  <mdb-icon
                    fas
                    icon="angle-down"
                    class="arrow-indicator"
                  ></mdb-icon>
                </h4>
              </mdb-card-title>

              <mdb-card-text *ngIf="lists.length">
                <ng-container *ngIf="char.list?.items?.length">
                  <app-item-display
                    [itemCollection]="char.list.items"
                    [listName]="char.list.Name"
                    [showDice]="true"
                    [char]="char"
                    class="collapse"
                  >
                  </app-item-display>
                </ng-container>
                <ng-container *ngIf="!char.list?.items?.length">
                  <div class="row">
                    <div class="col-12 mb-2">
                      <select
                        class="browser-default custom-select"
                        (change)="onListSelected($event)"
                      >
                        <option selected>Liste auswählen</option>
                        <option *ngFor="let list of lists">
                          {{ list.Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-12">
                      <button
                        mdbBtn
                        type="button"
                        size="sm"
                        color="green"
                        class="success-color-dark"
                        mdbWavesEffect
                        block="true"
                        (click)="addListToChar(char.Name)"
                      >
                        Liste auswählen
                      </button>
                    </div>
                  </div>
                </ng-container>
              </mdb-card-text>
              <mdb-card-text *ngIf="!lists.length">
                <ng-container>
                  Du musst zuerst eine Liste erstellen.
                </ng-container>
              </mdb-card-text>

              <div class="row">
                <div class="col-md-9"></div>
                <div class="col-12 col-md-3">
                  <button
                    mdbBtn
                    type="button"
                    size="sm"
                    color="red"
                    class="orange darken-4"
                    mdbWavesEffect
                    block="true"
                    (click)="deleteChar(char.Name)"
                  >
                    Char löschen
                  </button>
                </div>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
      <div class="title row">
        <div class="col-12 mb-3" *ngIf="!chars.length">
          <mdb-card>
            <!--Card content-->
            <mdb-card-body>
              <!--Title-->
              <mdb-card-title>
                <h4 class="text-center no-border mb-0 default-cursor">
                  Keine Chars Angelegt
                </h4>
              </mdb-card-title>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-9"></div>
            <div class="col-12 col-md-3">
              <button
                mdbBtn
                type="button"
                size="sm"
                color="green"
                class="success-color-dark"
                mdbWavesEffect
                block="true"
                (click)="openCharCreator()"
              >
                Char erstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-char-creator></app-char-creator>

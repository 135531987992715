<ul class="list-group my-3" *ngFor="let release of releaseNotesCollection">
  <li class="list-group-item d-flex justify-content-between align-items-center py-2">
     {{ release.Name }}&nbsp;-&nbsp;{{ release.Version }}
    <span class="badge badge-primary badge-pill">{{
      release.Notes.length
    }}</span>
  </li>
  <li *ngFor="let releaseNote of release.Notes" class="list-group-item d-flex justify-content-between align-items-center pl-5 py-1">
    {{ releaseNote }}
  </li>
</ul>

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var ListService = /** @class */ (function () {
    function ListService() {
        var _this = this;
        this.onListSelectorStateChange = new Subject();
        this.onListChange = new Subject();
        this.listSelectorOpenState = false;
        this._lists = [];
        this._lists = JSON.parse(localStorage.getItem('lists')) || [];
        this.onListChange.next(this._lists);
        this.onListChange.subscribe(function (lists) {
            if (_this._lists && _this._lists.length) {
                localStorage.setItem('lists', JSON.stringify(_this._lists));
            }
            if (!_this._lists.length) {
                localStorage.setItem('lists', JSON.stringify([]));
            }
        });
    }
    ListService.prototype.ngOnInit = function () { };
    ListService.prototype.openListSelector = function (item) {
        var _this = this;
        this.selectedItem = item;
        this.onListSelectorStateChange.next((this.listSelectorOpenState = true));
        this._lists = this._lists.map(function (listItem) {
            return tslib_1.__assign({}, listItem, { occupied: !!listItem.items.find(function (x) { return x.Name === _this.selectedItem.Name; }) });
        });
        this.onListChange.next(this._lists);
    };
    ListService.prototype.closeListSelector = function () {
        this.onListSelectorStateChange.next((this.listSelectorOpenState = false));
    };
    ListService.prototype.createList = function (listName) {
        var newList = { Name: listName, items: [] };
        if (this.selectedItem['Name']) {
            newList.items.push(this.selectedItem);
        }
        if (!this.isListNameTaken(listName)) {
            this._lists.unshift(newList);
            this.onListChange.next(this._lists);
        }
        else {
            alert('Dieser Listenname ist bereits vorhanden. Bitte wähle einen anderen.');
        }
        this.closeListSelector();
    };
    ListService.prototype.isListNameTaken = function (listName) {
        return this._lists.find(function (x) {
            return x.Name === listName;
        });
    };
    Object.defineProperty(ListService.prototype, "lists", {
        get: function () {
            return this._lists;
        },
        enumerable: true,
        configurable: true
    });
    ListService.prototype.deleteList = function (listName) {
        this._lists = this._lists.filter(function (x) { return x.Name !== listName; });
        this.onListChange.next(this._lists);
    };
    ListService.prototype.addItemToList = function (listName) {
        var _this = this;
        this._lists.forEach(function (x) {
            if (x.Name.trim() === listName.trim()) {
                var hasItem = x.items.find(function (y) { return y.Name === _this.selectedItem.Name; });
                if (!hasItem) {
                    x.items.push(_this.selectedItem);
                }
                else {
                    alert(_this.selectedItem.Name + ' befindet sich bereits in: ' + listName);
                }
            }
        });
    };
    ListService.prototype.deleteItemFromList = function (itemName, listName) {
        for (var i = 0; i < this.lists.length; i++) {
            var currentList = this.lists[i];
            if (currentList.Name.trim() !== listName.trim()) {
                continue;
            }
            for (var j = 0; j < currentList.items.length; j++) {
                var item = currentList.items[j];
                if (item.Name === itemName) {
                    var confirmDelete = confirm('Bist du sicher, dass du: ' +
                        itemName +
                        ' aus: ' +
                        listName +
                        ' löschen willst ?');
                    if (confirmDelete) {
                        currentList.items.splice(j, 1);
                    }
                    return;
                }
            }
        }
        this.onListChange.next(this._lists);
    };
    ListService.ngInjectableDef = i0.defineInjectable({ factory: function ListService_Factory() { return new ListService(); }, token: ListService, providedIn: "root" });
    return ListService;
}());
export { ListService };

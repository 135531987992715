import { Component, OnInit } from '@angular/core';
import { SearchService } from '../services/search.service.js';
import { FilterType } from '../enums/FilterType';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  displayData = [];
  dynamicFormClass: string;

  constructor(private ss: SearchService) {
    this.ss.onItemGet().subscribe(data => {
      data.length
        ? (this.dynamicFormClass = 'active-display')
        : (this.dynamicFormClass = '');

      this.displayData = data;
    });
  }
  debounce = (callback, wait) => {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  debounceHandleSearch = this.debounce(async data => {
    const searchTerm = data.target.value.trim();
    this.ss.getfilteredData(searchTerm, FilterType.Name);
  }, 650);

  handleSearch($event) {
    this.debounceHandleSearch($event);
  }

  async ngOnInit() {}

  onSubmit($event) {
    $event.preventDefault();
  }
}

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';
import { debug } from 'util';

@Component({
  selector: 'app-dice',
  templateUrl: './dice.component.html',
  styleUrls: ['./dice.component.scss']
})

//basic code from https://codepen.io/anon/pen/jjdVML
//angular implementation by Mensur
export class DiceComponent implements OnInit {
  @ViewChild('die') die: ElementRef;
  @Output() afterDiceRoll = new EventEmitter<number>();
  @Input() color = 'red';
  sides = 20;
  initialSide = 1;
  lastFace;
  timeoutId;
  transitionDuration = 500;
  animationDuration = 2000;
  dieEl;
  constructor() {}

  ngOnInit() {
    this.dieEl = this.die.nativeElement;
    this.rollTo(this.initialSide);
  }

  throw() {
    this.dieEl.classList.add('rolling');

    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.dieEl.classList.remove('rolling');
      const targetFace = this.randomFace();

      this.rollTo(targetFace);
      this.afterDiceRoll.emit(targetFace);
    }, this.animationDuration);
  }

  randomFace() {
    const face = Math.floor(Math.random() * this.sides) + this.initialSide;
    this.lastFace = face === this.lastFace ? this.randomFace() : face;

    return face;
  }

  rollTo(face) {
    clearTimeout(this.timeoutId);
    this.dieEl.setAttribute('data-face', face);
  }
}

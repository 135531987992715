import { Component, OnInit, ComponentRef } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { DiceComponent } from '../dice/dice.component';

@Component({
  selector: 'app-item-calculator',
  templateUrl: './item-calculator.component.html',
  styleUrls: ['./item-calculator.component.scss']
})
export class ItemCalculatorComponent implements OnInit {
  char: any;
  item: any;
  itemProbe = [];
  diceValues = [] as number[];

  meisterlich: boolean;
  gelungen: boolean;
  nichtGelungen: boolean;
  patzer: boolean;

  erschwernis = 0;
  punkteVerbraucht = 0;
  message = '';

  constructor(public modalRef: MDBModalRef) {}

  ngOnInit() {
    this.itemProbe = this.item.Probe.split('/').map(x =>
      x.toUpperCase().trim()
    );
  }
  throwDice(dice1, dice2, dice3) {
    dice1.throw();
    dice2.throw();
    dice3.throw();
  }

  handleDice1(result) {
    this.resetDiceVals();
    this.diceValues.push(result);
  }

  handleDice2(result) {
    this.diceValues.push(result);
  }

  handleDice3(result) {
    this.diceValues.push(result);
    this.setResultType();
    console.log(this.diceValues.join(' - '));
  }

  resetDiceVals() {
    this.diceValues = [];
    this.punkteVerbraucht = 0;
  }

  setResultType() {
    if (this.autoCalc()) {
      return;
    }

    this.calc();
  }
  calc() {
    for (let i = 0; i < this.diceValues.length; i++) {
      const val = this.diceValues[i];
      const currentCharVal = this.char[this.itemProbe[i]] - this.erschwernis;

      if (currentCharVal - val < 0) {
        this.punkteVerbraucht += Math.abs(val - currentCharVal);
      }
    }
    this.message = this.punkteVerbraucht + ' Punkte verbraucht!';
    this.gelungen = true;
  }
  autoCalc() {
    this.patzer = this.diceValues.filter(x => x === 20).length >= 2;
    if (this.patzer) {
      this.message = 'Patzer!: ' + this.diceValues.join(' - ');
      this.meisterlich = false;
      this.nichtGelungen = false;
      this.gelungen = false;
      return true;
    }

    this.meisterlich = this.diceValues.filter(x => x === 1).length >= 2;
    if (this.meisterlich) {
      this.message = 'Meisterlich!: ' + this.diceValues.join(' - ');
      this.patzer = false;
      this.nichtGelungen = false;
      this.gelungen = false;
      return true;
    }

    this.nichtGelungen = this.diceValues.filter(x => x === 20).length === 1;
    if (this.nichtGelungen) {
      this.message = 'Meisterlich!: ' + this.diceValues.join(' - ');
      this.patzer = false;
      this.meisterlich = false;
      this.gelungen = false;
      this.message =
        'Nicht gelungen. 20 dabei!: ' + this.diceValues.join(' - ');
      return true;
    }
  }
}

<div class="row">
  <div
    *ngFor="let item of itemCollection"
    class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
  >
    <div class="item">
      <mdb-badge pill="true" class="mb-3" default="true" size="2x">
        {{ item.Kategorie }}
      </mdb-badge>

      <p><strong>Name:&nbsp;</strong>{{ item.Name }}</p>
      <p *ngIf="!!item.Probe"><strong>Probe:&nbsp;</strong>{{ item.Probe }}</p>

      <p class="mb-2" *ngIf="!!item['AsP-Kosten']"><strong>Kosten: </strong>{{ item['AsP-Kosten'] }}</p>

      <div class="row mt-3">
        <div class="col text-center">
          <a mdbBtn floating="true" size="md" (click)="openModal(item)">
            <mdb-icon fas icon="align-justify"></mdb-icon>
          </a>
        </div>
        <div class="col text-center">
          <a mdbBtn floating="true" size="md" (click)="openListSelector(item)">
            <mdb-icon style="color:green;" fas icon="plus"></mdb-icon>
          </a>
        </div>
        <div class="col text-center" *ngIf="showDice && !!item.Probe">
          <a mdbBtn floating="true" size="md" (click)="openDiceModal(char, item)">
            <mdb-icon style="color:blue;" fas icon="dice"></mdb-icon>
          </a>
        </div>
        <div class="col text-center" *ngIf="showDeleteButton">
          <a
            mdbBtn
            floating="true"
            size="md"
            (click)="deleteItemFromList(item.Name)"
          >
            <mdb-icon style="color:red;" fas icon="minus"></mdb-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

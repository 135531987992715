import { Component, OnInit, Input } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ItemModalComponent } from '../item-modal/item-modal.component';
import { ListService } from 'src/app/shared/services/list.service';
import { ItemCalculatorComponent } from '../item-calculator-component/item-calculator.component';

@Component({
  selector: 'app-item-display',
  templateUrl: './item-display.component.html',
  styleUrls: ['./item-display.component.scss'],
  host: { class: 'container' }
})
export class ItemDisplayComponent implements OnInit {
  @Input() showDeleteButton: boolean;
  @Input() itemCollection: [];
  @Input() listName: string;
  @Input() showDice: boolean;
  @Input() char: any;

  modalRef: MDBModalRef;

  constructor(private ms: MDBModalService, private listService: ListService) {}

  ngOnInit() {}

  openListSelector(item) {
    this.listService.openListSelector(item);
  }

  openModal(item) {
    const itemCopy = Object.assign({}, item);

    const cleanedItem = {};

    for (const key in itemCopy) {
      const value = itemCopy[key];

      if (key === 'ID' || !value) {
        continue;
      }
      cleanedItem[key] = value;
    }

    this.modalRef = this.ms.show(ItemModalComponent, {
      class: 'modal-lg modal-dialog-scrollable',
      data: {
        item: cleanedItem
      }
    });
  }

  deleteItemFromList(itemName) {
    if (this.listName) {
      this.listService.deleteItemFromList(itemName, this.listName);
    }
  }
  openDiceModal(char, item) {
    const itemCopy = Object.assign({}, char);
    this.modalRef = this.ms.show(ItemCalculatorComponent, {
      class: 'modal-md modal-dialog-scrollable',
      data: {
        char,
        item
      }
    });
  }
}

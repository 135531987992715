<div class="row">
  <div class="col-md-12">
    <form
      class="form-inline md-form form-sm {{ dynamicFormClass }}"
      (submit)="onSubmit($event)"
    >
      <!-- <i class="fas fa-search" aria-hidden="true"></i> -->
      <input
        class="form-control form-control-sm w-100"
        type="text"
        placeholder="Suche..."
        aria-label="Search"
        mdbInput
        (keydown)="handleSearch($event)"
      />
    </form>
  </div>
  <app-item-display [itemCollection]="displayData"></app-item-display>

</div>

<div id="bottom-sheet" class="overlay {{ isCharSelectorOpen ? 'open' : '' }}">
  <aside
    class="social py-3"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-label"
    aria-hidden="true"
  >
    <div class="container">
      <div class="row" *ngIf="isCharCreatorOpen">
        <div class="col-12">
          <div class="md-form mb-3">
            <input
              mdbInput
              type="text"
              id="newListName"
              class="form-control"
              [(ngModel)]="newCharName"
            />
            <label for="newListName">Name des neuen Chars</label>
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-3">
              <div class="md-form">
                <input
                  mdbInput
                  type="number"
                  id="mu"
                  class="form-control form-control-sm"
                  [(ngModel)]="attr.MU"
                />
                <label for="mu" class="">MU</label>
              </div>
            </div>
            <div class="col-3">
              <div class="md-form">
                <input
                  mdbInput
                  type="number"
                  id="kl"
                  class="form-control form-control-sm"
                  [(ngModel)]="attr.KL"
                />
                <label for="kl" class="">KL</label>
              </div>
            </div>
            <div class="col-3">
              <div class="md-form">
                <input
                  mdbInput
                  type="number"
                  id="in"
                  class="form-control form-control-sm"
                  [(ngModel)]="attr.IN"
                />
                <label for="in" class="">IN</label>
              </div>
            </div>
            <div class="col-3">
              <div class="md-form">
                <input
                  mdbInput
                  type="number"
                  id="ch"
                  class="form-control form-control-sm"
                  [(ngModel)]="attr.CH"
                />
                <label for="ch" class="">CH</label>
              </div>
            </div>
            <div class="col-3">
              <div class="md-form">
                <input
                  mdbInput
                  type="number"
                  id="ff"
                  class="form-control form-control-sm"
                  [(ngModel)]="attr.FF"
                />
                <label for="ff" class="">FF</label>
              </div>
            </div>
            <div class="col-3">
              <div class="md-form">
                <input
                  mdbInput
                  type="number"
                  id="ge"
                  class="form-control form-control-sm"
                  [(ngModel)]="attr.GE"
                />
                <label for="ge" class="">GE</label>
              </div>
            </div>
            <div class="col-3">
              <div class="md-form">
                <input
                  mdbInput
                  type="number"
                  id="ko"
                  class="form-control form-control-sm"
                  [(ngModel)]="attr.KO"
                />
                <label for="ko" class="">KO</label>
              </div>
            </div>
            <div class="col-3">
              <div class="md-form">
                <input
                  mdbInput
                  type="number"
                  id="kk"
                  class="form-control form-control-sm"
                  [(ngModel)]="attr.KK"
                />
                <label for="kk" class="">KK</label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-2">
          <button
            mdbBtn
            type="button"
            size="sm"
            color="dark-green"
            class="success-color-dark"
            mdbWavesEffect
            block="true"
            (click)="saveNewChar()"
            [disabled]="!newCharName"
            class="mb-2"
          >
            Neuen Char Speichern
          </button>
        </div>
        <div class="col-12">
          <button
            mdbBtn
            type="button"
            size="sm"
            color="red"
            mdbWavesEffect
            class="orange darken-4"
            block="true"
            (click)="isCharCreatorOpen = false"
          >
            Abbrechen
          </button>
        </div>
      </div>

      <div class="row" *ngIf="!isCharCreatorOpen">
        <div class="col-6">
          <button
            mdbBtn
            type="button"
            size="sm"
            color="red"
            mdbWavesEffect
            class="orange darken-4"
            block="true"
            (click)="close()"
          >
            Abbrechen
          </button>
        </div>
        <div class="col-6">
          <button
            mdbBtn
            type="button"
            size="sm"
            class="success-color-dark"
            color="dark-green"
            mdbWavesEffect
            block="true"
            (click)="isCharCreatorOpen = true"
          >
            Neuer Char
          </button>
        </div>
      </div>
    </div>
  </aside>
  <a (click)="close()" class="btn-close" aria-hidden="true">
    <span class="mdi mdi-close"></span>
    <span class="sr-only">Close</span>
  </a>
</div>

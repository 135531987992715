/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./item-modal.component";
import * as i4 from "angular-bootstrap-md";
var styles_ItemModalComponent = [i0.styles];
var RenderType_ItemModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemModalComponent, data: {} });
export { RenderType_ItemModalComponent as RenderType_ItemModalComponent };
function View_ItemModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", ":\u00A0 "])), (_l()(), i1.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.key; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 5, 0, currVal_1); }); }
export function View_ItemModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "modal-header p-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-11"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h4", [["class", "modal-title w-100"], ["id", "itemmodal"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ItemModalComponent_1)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.KeyValuePipe, [i1.KeyValueDiffers]), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "attr"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.item)); _ck(_v, 13, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.Name; _ck(_v, 9, 0, currVal_0); }); }
export function View_ItemModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-item-modal", [], null, null, null, View_ItemModalComponent_0, RenderType_ItemModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ItemModalComponent, [i4.MDBModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItemModalComponentNgFactory = i1.ɵccf("app-item-modal", i3.ItemModalComponent, View_ItemModalComponent_Host_0, {}, {}, []);
export { ItemModalComponentNgFactory as ItemModalComponentNgFactory };

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemDisplayComponent } from './item-display/item-display.component';
import { SharedModule } from '../shared/shared.module';
import { ItemModalComponent } from './item-modal/item-modal.component';
import { ItemCalculatorComponent } from './item-calculator-component/item-calculator.component';
import { FormsModule } from '@angular/forms';
import { DiceComponent } from './dice/dice.component';

@NgModule({
  declarations: [ItemDisplayComponent, ItemModalComponent, ItemCalculatorComponent, DiceComponent],
  entryComponents: [
    ItemModalComponent,
    ItemDisplayComponent,
    ItemCalculatorComponent
  ],
  imports: [CommonModule, SharedModule, FormsModule],
  exports: [ItemDisplayComponent, ItemModalComponent, ItemCalculatorComponent]
})
export class ItemModule {}

<div class="content">
  <div class="die" #die>
    <figure [style.borderBottomColor]="color" class="face face-1"></figure>
    <figure [style.borderBottomColor]="color" class="face face-2"></figure>
    <figure [style.borderBottomColor]="color" class="face face-3"></figure>
    <figure [style.borderBottomColor]="color" class="face face-4"></figure>
    <figure [style.borderBottomColor]="color" class="face face-5"></figure>
    <figure [style.borderBottomColor]="color" class="face face-6"></figure>
    <figure [style.borderBottomColor]="color" class="face face-7"></figure>
    <figure [style.borderBottomColor]="color" class="face face-8"></figure>
    <figure [style.borderBottomColor]="color" class="face face-9"></figure>
    <figure [style.borderBottomColor]="color" class="face face-10"></figure>
    <figure [style.borderBottomColor]="color" class="face face-11"></figure>
    <figure [style.borderBottomColor]="color" class="face face-12"></figure>
    <figure [style.borderBottomColor]="color" class="face face-13"></figure>
    <figure [style.borderBottomColor]="color" class="face face-14"></figure>
    <figure [style.borderBottomColor]="color" class="face face-15"></figure>
    <figure [style.borderBottomColor]="color" class="face face-16"></figure>
    <figure [style.borderBottomColor]="color" class="face face-17"></figure>
    <figure [style.borderBottomColor]="color" class="face face-18"></figure>
    <figure [style.borderBottomColor]="color" class="face face-19"></figure>
    <figure [style.borderBottomColor]="color" class="face face-20"></figure>
  </div>
</div>

<!-- <a href="" class="randomize">Roll!</a> -->

<div class="modal-content">
  <div class="modal-header p-1">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">×</span>
    </button>
    <div class="container">
      <div class="row">
        <div class="col-11">
          <h4 class="modal-title w-100" id="calc">
            {{ item.Name }}
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="md-form form-sm erschwernis">
            <input
              mdbInput
              type="number"
              id="Erschwernis"
              class="form-control form-control-sm"
              [(ngModel)]="erschwernis"
            />

            <label for="Erschwernis" class=""
              >Erschwernis/Erleichterung (+/-)</label
            >
          </div>
        </div>
        <div class="col-12 mb-3">
          <strong>Probe: </strong>
          {{ itemProbe[0] }}
          <strong>({{ char[itemProbe[0]] - erschwernis }}) /</strong>
          {{ itemProbe[1] }}
          <strong>({{ char[itemProbe[1]] - erschwernis }}) /</strong>
          {{ itemProbe[2] }}
          <strong>({{ char[itemProbe[2]] - erschwernis }})</strong>
        </div>

        <div class="col-12 mb-3">
          <div class="container">
            <div class="row">
              <div class="col-4">
                <app-dice
                  [color]="'green'"
                  #diceComp1
                  (afterDiceRoll)="handleDice1($event)"
                ></app-dice>
              </div>
              <div class="col-4">
                <app-dice
                  [color]="'red'"
                  #diceComp2
                  (afterDiceRoll)="handleDice2($event)"
                ></app-dice>
              </div>
              <div class="col-4">
                <app-dice
                  [color]="'black'"
                  #diceComp3
                  (afterDiceRoll)="handleDice3($event)"
                ></app-dice>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="alert alert-light" *ngIf="meisterlich">
            {{ message }}
          </div>

          <div class="alert alert-success" role="alert" *ngIf="gelungen">
            {{ message }}
          </div>

          <div class="alert alert-warning" role="alert" *ngIf="nichtGelungen">
            {{ message }}
          </div>

          <div class="alert alert-dark" *ngIf="patzer">
            {{ message }}
          </div>
        </div>

        <div class="col-12">
          <button
            mdbBtn
            type="button"
            size="sm"
            color="green"
            class="success-color-dark"
            mdbWavesEffect
            block="true"
            (click)="throwDice(diceComp1, diceComp2, diceComp3)"
          >
            Probe Würfeln
          </button>
        </div>
      </div>
    </div>
    <div class="attr"></div>
  </div>
</div>

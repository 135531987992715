import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FilterType } from '../enums/FilterType';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private filteredData = [];
  private currentSearchTerm = '';
  private itemSubject = new Subject<any>();

  constructor(private api: ApiService) {}

  async getfilteredData(searchTerm: string, option: FilterType) {
    this.currentSearchTerm = searchTerm;
    const normalizedString = this.currentSearchTerm.trim().toLowerCase();

    if (!normalizedString) {
      this.currentSearchTerm = '';
      this.filteredData = [];
      this.itemSubject.next(this.filteredData);

      return;
    }

    this.currentSearchTerm = searchTerm;

    switch (option) {
      case FilterType.Name:
        this.filteredData = (await this.api.getSearchData(
          normalizedString,
          FilterType.Name
        ))['data'];
        break;

      case FilterType.Attribute:
        this.filteredData = (await this.api.getSearchData(
          normalizedString,
          FilterType.Attribute
        ))['data'];
        break;

      case FilterType.StepupFactor:
        this.filteredData = (await this.api.getSearchData(
          normalizedString,
          FilterType.StepupFactor
        ))['data'];
        break;

      default:
        this.filteredData = (await this.api.getSearchData(
          normalizedString,
          FilterType.Name
        ))['data'];
        break;
    }

    this.itemSubject.next(this.filteredData);
  }

  onItemGet() {
    return this.itemSubject.asObservable();
  }

  filterByAttribute(searchString: string) {
    // return this.filteredData || ([] as any);
  }

  filterByStepupFactor(searchString: string) {
    // return this.filteredData || ([] as any);
  }
}

import { Component, OnInit } from '@angular/core';
import { CharService } from 'src/app/shared/services/char.service';
import { ListService } from 'src/app/shared/services/list.service';

@Component({
  selector: 'app-char-page',
  templateUrl: './char-page.component.html',
  styleUrls: ['./char-page.component.scss']
})
export class CharPageComponent implements OnInit {
  chars = [];
  lists = [];
  selectedListName = '';

  constructor(
    private charService: CharService,
    private listService: ListService
  ) {}

  ngOnInit() {
    this.chars = this.charService.lists;
    this.lists = this.listService.lists;

    this.charService.onListChange.subscribe(chars => (this.chars = chars));
    this.listService.onListChange.subscribe(lists => (this.lists = lists));
  }

  openCharCreator(item?) {
    this.charService.openListSelector(null);
  }

  deleteChar(charName) {
    const canDelete = confirm(
      'Willst du den char ' + charName + ' wirklich löschen ?'
    );
    if (canDelete) {
      this.charService.deleteChar(charName);
    }
  }

  onListSelected($event) {
    this.selectedListName = $event.target.selectedOptions[0].text;
  }

  addListToChar(charName) {
    if(!this.selectedListName) {
      return;
    }
    this.charService.addListToChar(
      charName,
      this.lists.find(x => x.Name === this.selectedListName)
    );
  }

  toggleCollapse($event) {
    const collapsedElement = $event.target.parentElement.parentElement.getElementsByTagName(
      'app-item-display'
    )[0];

    if(!collapsedElement) {
      return;
    }
    const IndicatorClassList = $event.target.children[0].classList;
    if (!collapsedElement) {
      return;
    }
    const elementClasses = collapsedElement.classList;

    IndicatorClassList.contains('open')
      ? IndicatorClassList.remove('open')
      : IndicatorClassList.add('open');

    elementClasses.contains('collapse')
      ? elementClasses.remove('collapse')
      : elementClasses.add('collapse');
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from './search/search.component';
import { CustomListDisplayComponent } from './custom-lists/custom-list-display/custom-list-display.component';
import { ReleaseNotesDisplayComponent } from './release-notes/components/release-notes-display/release-notes-display.component';
import { CharPageComponent } from './char/components/char-page/char-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/suche', pathMatch: 'full' },
  { path: 'suche', component: SearchComponent },
  { path: 'listen', component: CustomListDisplayComponent },
  { path: 'char', component: CharPageComponent },
  { path: 'release-notes', component: ReleaseNotesDisplayComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

<div class="modal-content">
  <div class="modal-header p-1">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"

    >
      <span aria-hidden="true">×</span>
    </button>
    <div class="container">
      <div class="row">
        <div class="col-11">
          <h4 class="modal-title w-100" id="itemmodal">
            {{ item.Name }}
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row" *ngFor="let it of item | keyvalue">
        <div class="col-12">
          <p>
            <strong> {{ it.key }}:&nbsp; </strong>
            {{ it.value }}
          </p>
        </div>
      </div>
    </div>
    <div class="attr"></div>
  </div>
</div>

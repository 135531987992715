<!-- <app-item-display [itemCollection]="lists"></app-item-display> -->
<div class="row mt-4">
  <div class="col-12" *ngFor="let item of lists">
    <div class="container">
      <div class="title row">
        <div class="col-12 mb-3">
          <mdb-card>
            <!--Card content-->
            <mdb-card-body>
              <!--Title-->
              <mdb-card-title (click)="toggleCollapse($event)">
                <h4 class="pr-4">
                  {{ item.Name }}
                  <mdb-icon
                    fas
                    icon="angle-down"
                    class="arrow-indicator"
                  ></mdb-icon>
                </h4>
              </mdb-card-title>

              <!--Text-->
              <mdb-card-text>
                <app-item-display
                  class="collapse"
                  [itemCollection]="item.items"
                  [showDeleteButton]="true"
                  [listName]="item.Name"
                ></app-item-display>
              </mdb-card-text>
              <div class="row">
                <div class="col-md-9"></div>
                <div class="col-12 col-md-3">
                  <button
                    mdbBtn
                    type="button"
                    size="sm"
                    color="red"
                    class="orange darken-4"
                    mdbWavesEffect
                    block="true"
                    (click)="deleteList(item.Name)"
                  >
                    Liste löschen
                  </button>
                </div>
              </div>
              <!-- <a href="#" mdbBtn color="primary" mdbWavesEffect>Button</a> -->
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="!lists.length">
    <div class="container">
      <div class="title row">
        <div class="col-12 mb-3">
          <mdb-card>
            <!--Card content-->
            <mdb-card-body>
              <!--Title-->
              <mdb-card-title>
                <h4 class="text-center no-border mb-0">
                  Noch keine Listen vorhanden
                </h4>
              </mdb-card-title>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CustomListDisplayComponent } from './custom-list-display/custom-list-display.component';
import { ItemModule } from '../item/item.module';

@NgModule({
  declarations: [CustomListDisplayComponent],
  imports: [CommonModule, SharedModule, ItemModule],
  exports: []
})

export class CustomListsModule {}

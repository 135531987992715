import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CharPageComponent } from './components/char-page/char-page.component';
import { ItemModule } from '../item/item.module';
import { ItemDisplayComponent } from '../item/item-display/item-display.component';

@NgModule({
  declarations: [CharPageComponent],
  imports: [
    CommonModule,
    SharedModule,
    ItemModule
  ]
})
export class CharPageModuleModule {

}

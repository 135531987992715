<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark elegant-color-dark">
  <mdb-navbar-brand>
    <a class="navbar-brand active" routerLink="/suche">
      DSA-Dict
    </a>
    <a class="navbar-brand active" routerLink="/listen"> Listen </a>
    <a class="navbar-brand active" routerLink="/char">
      Char
    </a>
    <a class="navbar-brand active" routerLink="/release-notes">
      Release Notes
    </a>
  </mdb-navbar-brand>

  <!-- <links>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link waves-light" routerLink="/listen" mdbWavesEffect>
          Listen
          <span class="sr-only">Listen</span>
        </a>
      </li>
    </ul>
  </links> -->
</mdb-navbar>

<div class="container pb-5">
  <router-outlet></router-outlet>
</div>

<app-add-to-list-selector></app-add-to-list-selector>

<footer class="page-footer font-small elegant-color-dark fixed-bottom">
  <div class="footer-copyright text-center py-3">
    {{ year }} by MenSur {{ releaseNotes[0].Version }}
  </div>
</footer>

<div
  mdbModal
  #basicModal="mdbModal"
  class="modal fade right"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myBasicModalLabel"
  aria-hidden="true"
  [config]="{
    ignoreBackdropClick: true,
    show: showAnalyticsModal
  }"
>
  <div class="modal-dialog modal-frame modal-bottom " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="myModalLabel">
          Google Analytics - Cookiehinweis
        </h4>
      </div>
      <div class="modal-body">
        <p>
          Diese Seite verwendet cookies um über google analytics
          Besucherstatistiken aufzustellen. Fall du nicht möchtest, dass deine
          Besucherstatistik nicht erfasst wird, benutze bitte folgendes
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
            >Addon.</a
          >
        </p>

        <p>
          Weitere Information zu Google Analytics findest du
          <a
            href="https://www.google.com/analytics/terms/de.html"
            target="_blank"
            >hier.</a
          >
        </p>
        Wenn du das Addon installiert hast und
        <a href="javascript:gaOptout()"><strong>hier</strong></a> clickst, wird
        das Opt-Out-Cookie gesetzt.
      </div>
      <div class="modal-footer">
        <button
          type="button"
          mdbBtn
          color="secondary"
          class="waves-light"
          aria-label="Close"
          (click)="markAsRead(basicModal)"
          mdbWavesEffect
        >
          Verwendung von Google Analytics zustimmen.
        </button>
      </div>
    </div>
  </div>
</div>

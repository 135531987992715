import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterType } from '../enums/FilterType';
import { endpoints } from '../../environments/local.endpoints';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {}

  async getSearchData(term: string, filter: FilterType) {
    return await this.http
      .get(endpoints.items + filter + '/' + term)
      .toPromise();
  }
}

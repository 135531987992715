const releaseNotes = [
  {
    Name: 'Release',
    Version: 'v1.2.2',
    Notes: [
      'Char-Attribute werden jetzt bei der Charaktererstellung festgelegt',
      'Zauber/AT-Pa - Rechner Feature im Char screen.'
    ]
  },
  {
    Name: 'Bugfix',
    Version: 'v1.2.1',
    Notes: ['Diverse Charaktererstellungsbugs gefixt.']
  },
  {
    Name: 'Release',
    Version: 'v1.2.0',
    Notes: ['Charaktererstellungsfeature implementiert']
  },
  {
    Name: 'Release',
    Version: 'v1.1.0',
    Notes: [
      '"Kategorie" Property an Items angefügt',
      'Kategorie indikator an den Items angefügt',
      'Serverupdate',
      'Instanzvariablen hinzugefügt',
      'Suchverzögerung erhöht',
      'CORS Header implementiert',
      'Listen öffnen/schließen indikator hinzugefügt',
      '"Zu listen hinzufügen" overlay zeigt jetzt an, in welchen listen das Ausgewählte item bereits drin ist.'
    ]
  },
  {
    Name: 'Hotfix',
    Version: 'v1.0.2',
    Notes: ['Releasenotes update im footer automatisiert']
  },
  {
    Name: 'Release',
    Version: 'v1.0.1',
    Notes: [
      'Releeasenote Seite hinzugefügt',
      'Color theme auf dark umgestellt',
      'Hinzufügen/Entfernen (+/-) buttons in Grün/Rot eingefärbt',
      'Deeplinks und redirects gefixt',
      '"Noch keine Listen vorhanden" - Hinweis hinzugefügt',
      'Navigation von Burgermenü auf eine gewöhnliche Navigation umgebaut'
    ]
  }
];

export { releaseNotes };

import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListService implements OnInit {
  onListSelectorStateChange = new Subject<any>();
  onListChange = new Subject<any>();

  listSelectorOpenState = false;
  selectedItem: any;
  _lists = [];

  constructor() {
    this._lists = JSON.parse(localStorage.getItem('lists')) || [];
    this.onListChange.next(this._lists);
    this.onListChange.subscribe(lists => {
      if (this._lists && this._lists.length) {
        localStorage.setItem('lists', JSON.stringify(this._lists));
      }
      if (!this._lists.length) {
        localStorage.setItem('lists', JSON.stringify([]));
      }
    });
  }

  ngOnInit() {}

  openListSelector(item) {
    this.selectedItem = item;
    this.onListSelectorStateChange.next((this.listSelectorOpenState = true));
    this._lists = this._lists.map(listItem => {
      return {
        ...listItem,
        occupied: !!listItem.items.find(x => x.Name === this.selectedItem.Name)
      };
    });
    this.onListChange.next(this._lists);
  }

  closeListSelector() {
    this.onListSelectorStateChange.next((this.listSelectorOpenState = false));
  }

  createList(listName) {
    const newList = { Name: listName, items: [] };

    if (this.selectedItem['Name']) {
      newList.items.push(this.selectedItem);
    }

    if (!this.isListNameTaken(listName)) {
      this._lists.unshift(newList);
      this.onListChange.next(this._lists);
    } else {
      alert(
        'Dieser Listenname ist bereits vorhanden. Bitte wähle einen anderen.'
      );
    }

    this.closeListSelector();
  }

  isListNameTaken(listName: string) {
    return this._lists.find(x => {
      return x.Name === listName;
    });
  }

  get lists() {
    return this._lists;
  }

  deleteList(listName: any) {
    this._lists = this._lists.filter(x => x.Name !== listName);
    this.onListChange.next(this._lists);
  }

  addItemToList(listName: any) {
    this._lists.forEach(x => {
      if (x.Name.trim() === listName.trim()) {
        const hasItem = x.items.find(y => y.Name === this.selectedItem.Name);
        if (!hasItem) {
          x.items.push(this.selectedItem);
        } else {
          alert(
            this.selectedItem.Name + ' befindet sich bereits in: ' + listName
          );
        }
      }
    });
  }
  deleteItemFromList(itemName, listName) {
    for (let i = 0; i < this.lists.length; i++) {
      const currentList = this.lists[i];
      if (currentList.Name.trim() !== listName.trim()) {
        continue;
      }
      for (let j = 0; j < currentList.items.length; j++) {
        const item = currentList.items[j];

        if (item.Name === itemName) {
          const confirmDelete = confirm(
            'Bist du sicher, dass du: ' +
              itemName +
              ' aus: ' +
              listName +
              ' löschen willst ?'
          );
          if (confirmDelete) {
            currentList.items.splice(j, 1);
          }
          return;
        }
      }
    }

    this.onListChange.next(this._lists);
  }
}

import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var CharService = /** @class */ (function () {
    function CharService() {
        var _this = this;
        this.onCharSelectorStateChange = new Subject();
        this.onListChange = new Subject();
        this.charSelectorOpenState = false;
        this._lists = [];
        this._lists = JSON.parse(localStorage.getItem('chars')) || [];
        this.onListChange.next(this._lists);
        this.onListChange.subscribe(function (lists) {
            if (_this._lists && _this._lists.length) {
                localStorage.setItem('chars', JSON.stringify(_this._lists));
            }
            if (!_this._lists.length) {
                localStorage.setItem('chars', JSON.stringify([]));
            }
        });
    }
    CharService.prototype.deleteListFromChars = function (listName) {
        this._lists.forEach(function (char) {
            if (char.list.Name === listName) {
                debugger;
                char.list = {
                    items: []
                };
            }
        });
        this.onListChange.next(this._lists);
    };
    CharService.prototype.closeCharCreator = function () {
        this.onCharSelectorStateChange.next((this.charSelectorOpenState = false));
    };
    CharService.prototype.openListSelector = function (char) {
        this.selectedChar = char;
        this.onCharSelectorStateChange.next((this.charSelectorOpenState = true));
        this.onListChange.next(this._lists);
    };
    CharService.prototype.createNewChar = function (charName, attr) {
        var newCar = tslib_1.__assign({ Name: charName }, attr, { list: [] });
        if (!this.isCharNameTaken(charName)) {
            this._lists.unshift(newCar);
            this.onListChange.next(this._lists);
        }
        else {
            alert('Dieser Charname ist schon bergeben. Bitte wähle einen anderen.');
        }
    };
    CharService.prototype.isCharNameTaken = function (charName) {
        return this._lists.find(function (x) { return x.Name === charName; });
    };
    CharService.prototype.deleteChar = function (charName) {
        this._lists = this._lists.filter(function (x) { return x.Name !== charName; });
        this.onListChange.next(this._lists);
    };
    Object.defineProperty(CharService.prototype, "lists", {
        get: function () {
            return this._lists;
        },
        enumerable: true,
        configurable: true
    });
    CharService.prototype.addListToChar = function (charName, list) {
        this._lists.forEach(function (char) {
            if (char.Name === charName) {
                char.list = list;
                return;
            }
        });
        this.onListChange.next(this._lists);
    };
    CharService.ngInjectableDef = i0.defineInjectable({ factory: function CharService_Factory() { return new CharService(); }, token: CharService, providedIn: "root" });
    return CharService;
}());
export { CharService };

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AddToListSelectorComponent } from './components/add-to-list-selector/add-to-list-selector.component';
import { FormsModule } from '@angular/forms';
import { CharCreatorComponent } from './components/char-creator/char-creator.component';

@NgModule({
  declarations: [AddToListSelectorComponent, CharCreatorComponent],
  entryComponents: [],
  imports: [FormsModule, MDBBootstrapModule.forRoot(), CommonModule],
  exports: [
    CommonModule,
    MDBBootstrapModule,
    AddToListSelectorComponent,
    CharCreatorComponent
  ]
})
export class SharedModule {}

import { Component, OnInit } from '@angular/core';
import { releaseNotes } from '../../../shared/release-notes/releaseNotes.js';

@Component({
  selector: 'app-release-notes-display',
  templateUrl: './release-notes-display.component.html',
  styleUrls: ['./release-notes-display.component.scss']
})

export class ReleaseNotesDisplayComponent implements OnInit {
  releaseNotesCollection = releaseNotes;

  constructor() {}

  ngOnInit() {}
}

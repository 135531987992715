<div id="bottom-sheet" class="overlay {{ isListSelectorOpen ? 'open' : '' }}">
  <aside
    class="social py-3"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-label"
    aria-hidden="true"
  >
    <div class="container">
      <div class="row" *ngIf="isListCreatorOpen">
        <div class="col-12">
          <div class="md-form">
            <input
              mdbInput
              type="text"
              id="newListName"
              class="form-control"
              [(ngModel)]="newListName"
            />
            <label for="newListName">Name der neuen Liste</label>
          </div>
        </div>

        <div class="col-12">
          <button
            mdbBtn
            type="button"
            size="sm"
            color="dark-green"
            class="success-color-dark"
            mdbWavesEffect
            block="true"
            (click)="saveNewList()"
            class="mb-2"
          >
            Neue Liste Speichern
          </button>
        </div>
        <div class="col-12">
          <button
            mdbBtn
            type="button"
            size="sm"
            color="red"
            mdbWavesEffect
            class="orange darken-4"
            block="true"
            (click)="isListCreatorOpen = false"
          >
            Abbrechen
          </button>
        </div>
      </div>

      <div class="row" *ngIf="!isListCreatorOpen">
        <div class="col-6">
          <button
            mdbBtn
            type="button"
            size="sm"
            color="red"
            mdbWavesEffect
            class="orange darken-4"
            block="true"
            (click)="close()"
          >
            Abbrechen
          </button>
        </div>
        <div class="col-6">
          <button
            mdbBtn
            type="button"
            size="sm"
            class="success-color-dark"
            color="dark-green"
            mdbWavesEffect
            block="true"
            (click)="createNewListStart()"
          >
            Neue Liste
          </button>
        </div>
      </div>
      <div class="row mt-3" *ngIf="lists.length">
        <div class="col-12 item-list" *ngFor="let list of lists">
          <button
            [disabled]="list.occupied"
            mdbBtn
            (click)="addItemToSelectedList(list.Name)"
            type="button"
            color="gold"
            block="true"
            class="list-item mb-1"
            flat="true"
          >
            {{ list.Name }}
          </button>
        </div>
      </div>
    </div>
  </aside>
  <a (click)="close()" class="btn-close" aria-hidden="true">
    <span class="mdi mdi-close"></span>
    <span class="sr-only">Close</span>
  </a>
</div>
